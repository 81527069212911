import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    poster: {
        width: "100%",
    },
}));

export default function TermsPoster(props) {
    const classes = useStyles();

    const handleClose = () => {
        props.close(false);
    };

    return (
        <div>
            <Dialog maxWidth="md" aria-labelledby="terms-and-conditions" open={props.openDialog}>
                <DialogTitle id="terms-and-conditions" align="center">
                    {props.lang === "en" ? "Terms and Conditions" : "Termes et Conditions"}
                </DialogTitle>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers>
                    {props.lang === "en" ? (
                        <img
                            src="/images/Terms_and_Conditions_1.jpg"
                            alt="terms and condition poster"
                            className={classes.poster}
                        />
                    ) : (
                        <img
                            src="/images/Terms_and_Conditions_1.jpg"
                            alt="terms and condition poster"
                            className={classes.poster}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}
