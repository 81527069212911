import React, { useState } from "react";
import { Button, Card, Divider, Grid, Link as MLink, makeStyles, Typography, useTheme } from "@material-ui/core";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { Link } from "react-router-dom";
import TermsPoster from "./TermsPoster";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: "url(/images/antlers_gun.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    header: { padding: theme.spacing(3) },
    card: {
        background: "rgba(255, 255, 255, 0.85)",
        width: "80%",
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(2),
        [theme.breakpoints.only("sm")]: {
            width: "95%",
        },
        [theme.breakpoints.only("xs")]: {
            width: "100%",
            marginTop: theme.spacing(0),
        },
    },
    container: {
        width: "75%",
        margin: "auto",
        padding: theme.spacing(2, 0),
        [theme.breakpoints.only("sm")]: {
            width: "95%",
        },
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
    },
    divider: {
        background: "black",
        margin: theme.spacing(6, "auto"),
        width: "50%",
    },
    body: {
        marginTop: theme.spacing(6),
    },
    button: {
        margin: theme.spacing(4, "auto", 0),
    },
    button2: {
        margin: theme.spacing(3, "auto", 6),
    },
    terms: {
        width: "50px",
    },
}));

export default function Home() {
    const classes = useStyles();
    const theme = useTheme();
    const [terms, setTerms] = useState(false);
    const [termsFr, setTermsFr] = useState(false);

    return (
        <div>
            <Header />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className={classes.background}
                style={{ minHeight: "100vh" }}
            >
                <Card className={classes.card}>
                    <div className={classes.container}>
                        <div>
                            <Typography variant="h2" align="center">
                                Steiner Predator Taxidermy Draw
                            </Typography>
                            <Typography variant="h5" align="center" style={{ textTransform: "uppercase" }}>
                                WIN UP TO $3,000 FOR TAXIDERMY
                            </Typography>
                        </div>
                        <div className={classes.body}>
                            <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }} gutterBottom>
                                Register your new STEINER PREDATOR binoculars or scope for the chance to win an ADVANCED
                                TAXIDERMY gift card of up to $3,000.
                            </Typography>
                            <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }}>
                                PURCHASES MUST BE MADE BETWEEN MAY 1ST TO OCTOBER 31ST, 2022.
                            </Typography>
                            <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }}>
                                One entry per purchase and/or email. Open to legal residents of Canada. Must be 18 years
                                or older to participate. Please read the{" "}
                                <MLink
                                    onClick={() => setTerms(true)}
                                    style={{ color: theme.palette.secondary.main, textDecoration: "underline" }}
                                >
                                    terms and conditions
                                </MLink>
                                .
                            </Typography>
                        </div>
                        <Divider className={classes.divider} />
                        <div>
                            <Typography variant="h2" align="center">
                                Tirage Steiner de Taxidermie
                            </Typography>
                            <Typography variant="h5" align="center" style={{ textTransform: "uppercase" }}>
                                GAGNEZ JUSQU’À 3,000$ POUR UNE TAXIDERMIE
                            </Typography>
                        </div>
                        <div className={classes.body}>
                            <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }} gutterBottom>
                                Enregistrez vos nouvelles jumelles ou lunette de visée STEINER PREDATOR et courez la
                                chance de gagner une carte-cadeau de ADVANCED TAXIDERMY allant jusqu’à 3,000$.
                            </Typography>
                            <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }}>
                                LES ACHATS DOIVENT ÊTRE EFFECTUÉS ENTRE LE 1ER MAI ET LE 31 OCTOBRE 2022.
                            </Typography>
                            <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }}>
                                Une participation par achat et/ou compte e-mail. Ouvert aux résidents légaux du Canada.
                                Doit être âgé de 18 ans ou plus pour participer. Veuillez lire les{" "}
                                <MLink
                                    onClick={() => setTermsFr(true)}
                                    style={{ color: theme.palette.secondary.main, textDecoration: "underline" }}
                                >
                                    termes et conditions
                                </MLink>
                                .
                            </Typography>
                        </div>
                    </div>
                    <div align="center" component={Button} style={{ margin: theme.spacing(4, "auto", 5) }}>
                        {/* <Tooltip placement="top" title="Canada Only / Seulement au Canada"> */}
                        <Button variant="contained" color="secondary" component={Link} to="/registration">
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ fontSize: "18px" }}>Enter Now</div>
                                <div style={{ fontSize: "10px" }}>Canada Only / Seulement au Canada</div>
                            </div>
                        </Button>
                        {/* </Tooltip> */}
                    </div>
                    <div align="center" style={{ margin: theme.spacing(3, "auto") }}>
                        <Typography variant="body1" gutterBottom>
                            In partnership with / En partenariat avec
                        </Typography>

                        <div>
                            <img src="/images/advanced-taxidermy-logo.png" alt="Advanced Taxidermy" />
                        </div>
                    </div>
                    <div align="center" className={classes.button}>
                        <a
                            style={{ color: "#000", fontSize: "16px", fontWeight: "Bold" }}
                            href="https://www.steiner-optics.com/repair-info"
                        >
                            Register Lifetime Warranty with Steiner
                        </a>
                    </div>
                    <div align="center" className={classes.button2}>
                        <Button component="a" href="https://www.stoegercanada.ca/brands/steiner/">
                            Return to Steiner Canada
                        </Button>
                        {/* <a
                            style={{ color: "#000", textDecoration: "none", textTransform: "uppercase" }}
                            href="https://www.stoegercanada.ca/brands/steiner/"
                        >
                            Return to Steiner Canada
                        </a> */}
                    </div>
                </Card>
                <Footer />
            </Grid>
            <TermsPoster openDialog={terms} close={(c) => setTerms(false)} lang="en" />
            <TermsPoster openDialog={termsFr} close={(c) => setTermsFr(false)} lang="fr" />
        </div>
    );
}
