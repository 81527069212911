import React from "react";
import AppRouter from "./AppRouter";
import CssBaseline from "@material-ui/core/CssBaseline";
// import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
    palette: {
        primary: {
            main: "#277cbf",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#277cbf",
            contrastText: "#ffffff",
        },
        textField: {
            main: "#ffffff",
            contrastText: "#ffffff",
        },
    },
    typography: {
        h1: {
            fontFamily: '"Roboto", sans-serif',
        },
        h2: {
            fontFamily: '"Roboto", sans-serif',
            //fontSize: "72px",
        },
        h3: {
            fontFamily: '"Roboto", sans-serif',
        },
        h4: {
            fontFamily: '"Roboto", sans-serif',
        },
        h5: {
            fontFamily: '"Roboto", sans-serif',
        },
        h6: {
            fontFamily: '"Roboto", sans-serif',
        },
        subtitle1: {
            fontFamily: '"Libre Baskerville", serif',
            fontStyle: "italic",
            fontSize: "24px",
        },
    },
});

function App() {
    return (
        <div>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppRouter />
            </ThemeProvider>
        </div>
    );
}

export default App;
