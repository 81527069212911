import React, { useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    Link,
    ListSubheader,
    makeStyles,
    MenuItem,
    Paper,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { useHistory } from "react-router";
import TermsPoster from "./TermsPoster";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: "url(/images/bino_hut.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    paper: {
        background: "rgba(255, 255, 255, 0.9)",
        width: "80%",
        margin: theme.spacing(8, "auto", 2),
        padding: theme.spacing(2, 3, 6),
        [theme.breakpoints.only("sm")]: {
            width: "95%",
        },
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
    },
    spacing: {
        margin: theme.spacing(1, 0),
    },
    spacing1: {
        margin: theme.spacing(1, 0, 0),
    },
    checks1: {
        margin: theme.spacing(1.5, "4px", 1),
    },
    checks: {
        margin: theme.spacing(1, "4px"),
    },
    button: {
        margin: theme.spacing(0, "auto", 0),
        width: "20%",
    },
    tmp: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: 1.43,
        letterSpacing: "0.01071em",
    },
    uploadLabel: {
        marginLeft: theme.spacing(1),
    },
    upload: {
        background: "rgba(255, 255, 255, 0.3)",
    },
    error: {
        border: "2px solid #f44336",
        // borderRadius: "6px",
        // color: "#f44336",
        margin: theme.spacing(4, 0, 2),
        // padding: theme.spacing(1.5, 1),
    },
}));

export default function Registration() {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [terms, setTerms] = useState(false);
    const [termsFr, setTermsFr] = useState(false);

    const [token, setToken] = useState("");
    const [poplulateForm, setPopulateForm] = useState({});
    const [upcError, setUpcError] = useState(false);
    const [error, setError] = useState({
        isExist: false,
        type: "",
        message: "",
    });

    const [form, setForm] = useState({
        first: "",
        last: "",
        email: "",
        province: "",
        telephone: "",
        model: "",
        upc: "",
        purchased_province: "",
        purchased_date: null,
        files: [],
        question1: "",
        question2: "",
        newsletter: false,
        terms: false,
    });
    const handleDateChange = (date) => {
        setForm({
            ...form,
            purchased_date: date,
        });
    };
    const handleImageUpload = (newFile) => {
        setForm({
            ...form,
            files: newFile,
        });
    };

    function handleFormData(e) {
        if (e.target.name === "newsletter" || e.target.name === "terms") {
            setForm({
                ...form,
                [e.target.name]: e.target.checked,
            });
        } else {
            // checking telephone field and only accepting numbers
            if (e.target.name === "telephone") {
                e.target.value.match("^[0-9]*$") &&
                    setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                    });
            } else {
                if (e.target.name === "upc") {
                    setUpcError(false);
                    setForm({
                        ...form,
                        [e.target.name]: e.target.value.length > 12 ? e.target.value.slice(0, 12) : e.target.value,
                    });
                } else {
                    setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                    });
                }
            }
        }
    }

    function hanldeSubmit(e) {
        e.preventDefault();

        setUpcError(false);
        setError({
            isExist: false,
            type: "",
            message: "",
        });
        const tmp = steralizeInput(form);
        // console.log(tmp);
        // fetch("http://localhost:8080/api/enterEntry", {
        fetch("https://predatordraw.steinercanada.ca/api/enterEntry", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "post",
            body: JSON.stringify({ token: token, entry_data: tmp }),
        })
            .then((res) => {
                if (res.status === 200) {
                    history.push("/thankyou");
                }
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                console.log("Data: ", data);
                if (data.error) {
                    handleError(data.error);
                }
            })
            .catch((err) => console.log(err));
    }

    // function testing() {
    //     // console.log("here");
    //     fetch("http://localhost:8080/api/testing", {
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         method: "post",
    //         body: JSON.stringify({
    //             token: token,
    //         }),
    //     })
    //         .then((res) => {
    //             return res.json();
    //         })
    //         .then((data) => {
    //             // console.log(data);
    //             setToken(data.access_token);
    //         })
    //         .catch((err) => console.log(err));
    // }

    useEffect(() => {
        // fetch("http://localhost:8080/api/getAccessToken", {
        fetch("https://predatordraw.steinercanada.ca/api/getAccessToken", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "post",
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setToken(data.access_token);
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (token !== "") {
            // testing();
            // fetch("http://localhost:8080/api/popluateForm", {
            fetch("https://predatordraw.steinercanada.ca/api/popluateForm", {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "post",
                body: JSON.stringify({
                    token: token,
                    fields: ["Province__c", "Model__c", "Province_Purchased__c", "Question_1__c", "Question_2__c"],
                }),
            })
                .then((res) => {
                    // console.log(res);
                    return res.json();
                })
                .then((data) => {
                    // console.log(data);
                    setPopulateForm(data);
                })
                .catch((err) => console.log(err));
        }
    }, [token]);

    //=============HELPER FUNCTIONS==================
    const steralizeInput = (form) => {
        let tmp = Object.entries(form);
        tmp = tmp.map((m) => (typeof m[1] === "string" ? [m[0], m[1].replace(/^\s+|\s+$/gm, "")] : m));

        return Object.fromEntries(tmp);
    };

    const handleError = (errMessage) => {
        if (errMessage.errorCode === "DUPLICATE_VALUE") {
            setError({
                isExist: true,
                type: "Duplicate Entry: ",
                message: "An Entry for this email has already been submitted.",
            });
        } else if (errMessage.errorCode === "FIELD_CUSTOM_VALIDATION_EXCEPTION") {
            errMessage.fields.includes("UPC__c") && setUpcError(true);
            setError({
                isExist: true,
                type: "Form Error: ",
                message: errMessage.message + ".",
            });
        }
    };

    return (
        <div>
            <Header />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                //justify="space-around"
                className={classes.background}
                style={{ minHeight: "100vh" }}
            >
                <Paper className={classes.paper}>
                    <Typography
                        variant="body1"
                        style={{
                            margin: "10px 0 0",
                            // , textTransform: "uppercase"
                        }}
                    >
                        Steiner Predator – Win up to $3,000 for taxidermy.
                    </Typography>
                    <Typography variant="h5" style={{ textTransform: "uppercase" }}>
                        Entry Registration:
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        style={{ textTransform: "uppercase" }}
                        gutterBottom
                    >
                        One entry per email / une soumission par courriel
                    </Typography>
                    <form onSubmit={hanldeSubmit}>
                        <Grid container spacing={1}>
                            <Grid item md={6} sm={12} xs={12}>
                                <TextField
                                    variant="outlined"
                                    className={classes.spacing}
                                    label="First Name / Prénom"
                                    fullWidth
                                    required={true}
                                    name="first"
                                    value={form.first}
                                    onChange={handleFormData}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <TextField
                                    variant="outlined"
                                    className={classes.spacing}
                                    label="Last Name / Nom de famille"
                                    fullWidth
                                    required={true}
                                    name="last"
                                    value={form.last}
                                    onChange={handleFormData}
                                />
                            </Grid>
                        </Grid>

                        <TextField
                            variant="outlined"
                            className={classes.spacing}
                            fullWidth
                            label="Email / Courriel"
                            required={true}
                            type="email"
                            name="email"
                            value={form.email}
                            onChange={handleFormData}
                        />

                        <Grid container spacing={1}>
                            <Grid item md={6} sm={12} xs={12}>
                                <TextField
                                    className={classes.spacing}
                                    required={true}
                                    fullWidth
                                    name="province"
                                    value={form.province}
                                    onChange={handleFormData}
                                    variant="outlined"
                                    label="Province / Province"
                                    select
                                >
                                    {poplulateForm.Province__c !== undefined ? (
                                        poplulateForm.Province__c.map((m) => (
                                            <MenuItem key={m.value} value={m.value}>
                                                {m.label}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <ListSubheader>Loading...</ListSubheader>
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <TextField
                                    variant="outlined"
                                    className={classes.spacing}
                                    pattern="[0-9]*"
                                    fullWidth
                                    type="text"
                                    label="Telephone Number / Numéro de Téléphone"
                                    name="telephone"
                                    value={form.telephone}
                                    required={true}
                                    onChange={handleFormData}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            className={classes.spacing}
                            required={true}
                            fullWidth
                            name="model"
                            value={form.model}
                            onChange={handleFormData}
                            variant="outlined"
                            label="Model / Modèle"
                            select
                        >
                            {poplulateForm.Model__c !== undefined ? (
                                poplulateForm.Model__c.map((m) => (
                                    <MenuItem key={m.value} value={m.value}>
                                        {m.label}
                                    </MenuItem>
                                ))
                            ) : (
                                <ListSubheader>Loading...</ListSubheader>
                            )}
                        </TextField>
                        <TextField
                            error={upcError}
                            variant="outlined"
                            className={classes.spacing1}
                            fullWidth
                            label="UPC / CUP"
                            required={true}
                            name="upc"
                            value={form.upc}
                            onChange={handleFormData}
                        />
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            The UPC on the Steiner binocular package / à le CUP sur la boîte de jumelles Steiner
                        </Typography>

                        <Grid container spacing={1}>
                            <Grid item md={6} sm={12} xs={12}>
                                <TextField
                                    className={classes.spacing}
                                    required={true}
                                    fullWidth
                                    name="purchased_province"
                                    value={form.purchased_province}
                                    onChange={handleFormData}
                                    variant="outlined"
                                    label="Purchased Province / Province d'achat"
                                    select
                                >
                                    {poplulateForm.Province_Purchased__c !== undefined ? (
                                        poplulateForm.Province_Purchased__c.map((m) => (
                                            <MenuItem key={m.value} value={m.value}>
                                                {m.label}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <ListSubheader>Loading...</ListSubheader>
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className={classes.spacing}
                                        fullWidth
                                        required={true}
                                        inputVariant="outlined"
                                        maxDate={new Date() + 1}
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Date of Purchased / Date d'achat"
                                        value={form.purchased_date}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            "aria-label": "change purchased date",
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <FormControl fullWidth className={classes.spacing}>
                            <Typography variant="body1" color="textSecondary" className={classes.uploadLabel}>
                                Upload receipt / Télécharger le reçus
                            </Typography>
                            <DropzoneArea
                                dropzoneClass={classes.upload}
                                onChange={(files) => handleImageUpload(files)}
                                maxFileSize={3000000}
                            />
                        </FormControl>

                        <TextField
                            className={classes.spacing}
                            required={true}
                            fullWidth
                            value={form.question1}
                            onChange={handleFormData}
                            variant="outlined"
                            name="question1"
                            label="Question 1: Where did you hear about this draw? / Où avez-vous entendu parler de cette offre?"
                            select
                        >
                            {poplulateForm.Question_1__c !== undefined ? (
                                poplulateForm.Question_1__c.map((m) => (
                                    <MenuItem key={m.value} value={m.value}>
                                        {m.label}
                                    </MenuItem>
                                ))
                            ) : (
                                <ListSubheader>Loading...</ListSubheader>
                            )}
                        </TextField>
                        <TextField
                            className={classes.spacing}
                            required={true}
                            fullWidth
                            value={form.question2}
                            onChange={handleFormData}
                            variant="outlined"
                            name="question2"
                            label="Question 2: Where did you first hear about Steiner Marine Binoculars? / Où avez-vous entendu parler des jumelles marines Steiner pour la première fois?"
                            select
                        >
                            {poplulateForm.Question_2__c !== undefined ? (
                                poplulateForm.Question_2__c.map((m) => (
                                    <MenuItem key={m.value} value={m.value}>
                                        {m.label}
                                    </MenuItem>
                                ))
                            ) : (
                                <ListSubheader>Loading...</ListSubheader>
                            )}
                        </TextField>
                        <div className={classes.checks1}>
                            <FormControl>
                                <Typography variant="body2" style={{ display: "inline" }}>
                                    <Checkbox
                                        checked={form.newsletter}
                                        size="small"
                                        onChange={handleFormData}
                                        name="newsletter"
                                        color="secondary"
                                        style={{ display: "inline-flex", padding: "0", marginRight: "8px" }}
                                    />
                                    <strong>
                                        Please subscribe me to Stoeger Canada, the Canadian distributor for Steiner and
                                        Steiner's Newsletter.
                                    </strong>{" "}
                                    By checking this box, I opt-in to receive periodic information on promotions, sales,
                                    events and new products as well as the chance to participate in any future draws by
                                    Stoeger Canada and Steiner. |{" "}
                                    <strong>
                                        S'il vous plaît inscrivez-moi au bulletin de Stoeger Canada, le distributeur
                                        canadien de Steiner.
                                    </strong>{" "}
                                    En cochant cette case, j'accepte de recevoir des informations périodiques sur les
                                    promotions, les ventes, les événements et les nouveaux produits, ainsi que la
                                    possibilité de participer aux tirages ultérieurs de Stoeger Canada et Steiner.
                                </Typography>
                            </FormControl>
                        </div>
                        <div className={classes.checks}>
                            <FormControl required>
                                <Typography variant="body2" style={{ display: "inline" }}>
                                    <Checkbox
                                        required={true}
                                        checked={form.terms}
                                        size="small"
                                        onChange={handleFormData}
                                        name="terms"
                                        color="secondary"
                                        style={{ display: "inline-flex", padding: "0", marginRight: "8px" }}
                                    />
                                    Accept{" "}
                                    <Link
                                        onClick={() => setTerms(true)}
                                        style={{
                                            color: theme.palette.secondary.main,
                                            textTransform: "none",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        <i>Terms & Conditions</i>
                                    </Link>{" "}
                                    | Accepter les{" "}
                                    <Link
                                        onClick={() => setTermsFr(true)}
                                        style={{
                                            color: theme.palette.secondary.main,
                                            textTransform: "none",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        <i>termes et conditions</i>
                                    </Link>{" "}
                                    *
                                </Typography>
                            </FormControl>
                        </div>
                        {error.isExist && (
                            // <div className={classes.error}>
                            //     <Typography variant="body2">
                            //         {error.type}
                            //         {error.message}
                            //     </Typography>
                            // </div>
                            <Alert severity="error" className={classes.error}>
                                {error.type}
                                {error.message}
                            </Alert>
                        )}
                        <div align="center">
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                style={{ margin: "15px 0 0", textTransform: "uppercase", fontWeight: "bold" }}
                            >
                                One entry per email / une soumission par courriel
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                type="submit"
                                // disabled
                            >
                                Submit
                            </Button>
                        </div>
                    </form>
                    {/* <Button variant="contained" color="primary" className={classes.button} onClick={testing}>
                        Testing
                    </Button> */}
                </Paper>
                <Footer />
            </Grid>
            <TermsPoster openDialog={terms} close={(c) => setTerms(false)} lang="en" />
            <TermsPoster openDialog={termsFr} close={(c) => setTermsFr(false)} lang="fr" />
        </div>
    );
}
